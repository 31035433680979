@use '@/styles/utils/mixins.scss' as *;

.findUsWraper {
  background-color: $white;
  padding: 3.75rem;
  @include tab() {
    padding: 3.75rem 0rem;
  }

  .findUsContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include tab() {
      gap: 3.75rem;
    }
  }
  .findUsHeader {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .findUsParagraph {
    width: 100%;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-size: 1.5rem;
      @include tab() {
        font-size: 1.25rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
